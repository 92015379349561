<template>
  <div class="text-right">
    <h4><i class="fas fa-coins" /> {{ creditsData.credits | credits }} <span class="d-none d-sm-inline"> Ticket Coins</span></h4>
    <b-button
      :to="{ name: 'getCredits' }"
      variant="gradient-primary"
      block
    >
      Comprar
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    creditsData: {
      type: Object,
      default: () => ({
        id: '',
        credits: 0,
      }),
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 950px) {
  h4 {
    font-size: 14px;
  }
  .btn {
    font-size: 9px;
    padding: .25rem;
  }
}
</style>
