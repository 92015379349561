<template>
  <div>
    <b-container class="p-2">
      <div class="d-flex aling-items-start mb-2">
        <a @click="$router.go(-1)">
          <feather-icon
            icon="ArrowLeftIcon"
            size="20"
          />
        </a>
      </div>
      <div class="d-flex justify-content-between">
        <user-info
          :user="currentUser"
          :total-purchases="totalCreditHistories"
        />
        <credits :credits-data="userCredits" />
      </div>
      <div class="d-flex mt-50">
        <div class="d-flex mr-1">
          <b-card
            no-body
            class="p-75 mr-50"
            bg-variant="info"
          >
            <div class="d-flex">
              <feather-icon
                class="text-white"
                icon="UserIcon"
              />
            </div>
          </b-card>
          <div>
            <h5 class="mb-0">
              {{ totalCreditHistories }}
            </h5>
            <p class="text-small">
              Compras
            </p>
          </div>
        </div>
        <div class="d-flex">
          <b-card
            no-body
            class="p-75 mr-50"
            bg-variant="warning"
          >
            <div class="d-flex">
              <feather-icon
                class="text-white"
                icon="UserIcon"
              />
            </div>
          </b-card>
          <div>
            <h5 class="mb-0">
              {{ currentUser.raffles_won }}
            </h5>
            <p class="text-small">
              Sorteos Ganados
            </p>
          </div>
        </div>
      </div>
      <winner-ticket
        v-for="ticket in rafflesWon"
        :key="`winner-${ticket.id}`"
        :data="ticket"
      />

      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Mis tickets
          </h4>
        </b-card-header>
        <div
          v-if="creditHistories.length === 0"
          class="d-flex flex-column justify-content-center "
        >
          <span class="text-center">
            No has participado en algún sorteo aún.
          </span>
          <b-button
            size="sm"
            variant="primary"
            :to="{ name: 'Sorteos' }"
          >
            Ver sorteos
          </b-button>
        </div>
        <b-table
          v-else
          responsive
          :items="creditHistories"
          :fields="historyFields"
        >
          <template #cell(credit_amount)="data">
            <i class="fas fa-coins" /> {{ data.value }}
          </template>
          <template #cell(tickets)="data">
            <div
              v-for="(ticket,n) in data.value"
              :key="n"
              class="raffle"
            >
              <div class="raffle-name">
                {{ ticket.name }}
              </div>
              <b-badge
                v-for="folio in ticket.tickets"
                :key="`${folio.id}`"
                :variant="new Date() > new Date(ticket.raffle_date) ? 'secondary' : 'primary'"
                class="mr-25"
              >
                {{ folio.folio }}
              </b-badge>
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ data.value | date }}
            <div> <i class="fas fa-clock" /> {{ data.value | time }} </div>
          </template>
        </b-table>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Historial de pagos
          </h4>
        </b-card-header>
        <div
          v-if="payments.length === 0"
          class="d-flex flex-column justify-content-center "
        >
          <span class="text-center">
            No has comprado ticket coins aún.
          </span>
          <b-button
            size="sm"
            variant="primary"
            :to="{ name: 'getCredits' }"
          >
            Compra créditos
          </b-button>
        </div>
        <b-table
          v-else
          responsive
          :items="payments"
          :fields="fields"
        >
          <template #cell(payment_status)="data">
            <b-badge
              :variant="getVariant(data.value)"
            >
              {{ data.value | payment_status }}
            </b-badge>
          </template>
          <template #cell(price)="data">
            ${{ data.value | money }} USD
          </template>
          <template #cell(credit_amount)="data">
            <i class="fas fa-coins" /> {{ data.value | credits }}
          </template>
          <template #cell(created_at)="data">
            <span> {{ data.value | date }} </span>
            <div>
              <i class="fas fa-clock" /> {{ data.value | time }}
            </div>
          </template>
        </b-table>
      </b-card>

    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserInfo from '@/views/users/UserData.vue'
import Credits from '@/components/Credits.vue'
import WinnerTicket from '@/views/users/WinnerCard.vue'

export default {
  components: {
    UserInfo,
    Credits,
    WinnerTicket,
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      fields: [
        {
          key: 'price',
          label: 'Total',
        },
        {
          key: 'credit_amount',
          label: 'Ticket Coins',
        },
        {
          key: 'payment_status',
          label: 'Estatus',
        },
        {
          key: 'created_at',
          label: 'Fecha',
        },
      ],
      historyFields: [
        {
          key: 'credit_amount',
          label: 'Ticket Coins',
        },
        {
          key: 'tickets',
          label: 'Boletos',
        },
        {
          key: 'created_at',
          label: 'Fecha de compra',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['currentUser', 'userCredits']),
    ...mapGetters('payments', ['payments']),
    ...mapGetters('raffles', ['rafflesWon']),
    ...mapGetters('credits', ['creditHistories', 'totalCreditHistories']),
  },
  beforeCreate() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  created() {
    this.getUser(this.$route.params.id)
    this.getUserCredits(this.$route.params.id)
    this.getCretitHistories({ by_user_id: this.$route.params.id, by_credit_type: 'debit' })
    this.getPayments({ by_user_id: this.$route.params.id })
    this.getRafflesWon({ by_winner_id: this.$route.params.id })
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  methods: {
    ...mapActions('users', ['getUser', 'getUserCredits']),
    ...mapActions('payments', ['getPayments']),
    ...mapActions('raffles', ['getRafflesWon']),
    ...mapActions('credits', ['getCretitHistories']),
    getVariant(status) {
      if (status === 'validated') {
        return 'success'
      } if (status === 'invalidated') {
        return 'danger'
      } if (status === 'pending') {
        return 'warning'
      }
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.text-small {
  font-size: 11px;
}
.raffle-name{
  font-size: 11px;
  font-weight: 600;
}
.raffle {
  margin-top: .5rem;
  &:first-child {
    margin-top: 0;
  }
}

</style>
