<template>
  <div class="d-flex">
    <div class="mr-1">
      <b-img
        :src="user.logo"
        class="profile-picture"
        rounded="rounded"
      />
    </div>
    <div>
      <h2 class="mb-0">
        {{ user.name }}
        <b-button
          size="sm"
          variant="link"
          class="edit-btn"
          :to="{ name: 'CustomerEditForm', params: { id: user.id} }"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
      </h2>
      <div class="email">
        {{ user.email }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPurchases: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      default: () => ({
        name: '',
        email: '',
      }),
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-picture {
  width: 95px;
}
@media screen and (max-width: 950px) {
  .profile-picture {
    width: 35px;
  }
  h2 {
    font-size: 14px;
  }
  .email {
    font-size: 10px;
  }
  .edit-btn {
    padding: 0;
  }
}
</style>
