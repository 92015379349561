<template>
  <b-card
    no-body
    class="overflow-hidden winner-card"
  >
    <div>
      <b-img
        :src="data.images[0]"
        fluid
      />
    </div>
    <div
      class="p-1 d-flex flex-column justify-content-center align-items-center"
    >
      <h2 class="text-center display-4">
        ¡Felicidades!
      </h2>
      <p class="mb-3">
        Tienes el ticket ganador
      </p>
      <div class="ticket">
        {{ data.winner_ticket }}
      </div>
      <b-button
        class="mt-3"
        variant="gradient-primary"
      >
        Reclamar premio
      </b-button>
    </div>

  </b-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.winner-card {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1200px) {
    grid-template-columns: 2fr 1fr;
  }
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  .ticket {
    width: 160px;
    height: 85px;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 30px;
    color: white;
    border-radius: 4px;
    position: relative;
    animation: pulse 2s ease infinite;
    &::before {
      content: '';
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 50%;
      position: absolute;
      right: -15px;
      top: calc(50% - 15px);
    }
    &::after {
      content: '';
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 50%;
      position: absolute;
      left: -15px;
      top: calc(50% - 15px);
    }

    @keyframes pulse {
      50% {
        transform: scale(110%) rotate(-3deg);
      }
    }
  }
}
</style>
